export const getDistrict = /* GraphQL */ `
    query GetDistrict($id: ID!) {
        getDistrict(id: $id) {
            id
            slug
            name {
                legal
                popular
            }
            avatar {
                size
                name
                src
            }
            address {
                line1
                line2
                city
                county
                state
                zip
            }
            zone {
                id
                name
                counties
            }
            staff {
                type
                title
                name {
                    first
                    last
                }
            }
            schools {
                items {
                    id
                    slug
                    name {
                        legal
                        popular
                    }
                    avatar {
                        size
                        name
                        src
                    }
                    state {
                        enabled
                    }
                }
            }
            users {
                items {
                    id
                    username
                    avatar {
                        size
                        name
                        src
                    }
                }
            }
            state {
                enabled
            }
            createdAt
            updatedAt
            districtZoneId
        }
    }
`;

export const getDistrictBySlug = /* GraphQL */ `
    query GetDistrictBySlug(
        $slug: String!
        $sortDirection: ModelSortDirection
        $filter: ModelDistrictFilterInput
        $limit: Int
        $nextToken: String
    ) {
        getDistrictBySlug(
            slug: $slug
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                beds
                slug
                name {
                    legal
                    popular
                }
                avatar {
                    size
                    name
                    src
                }
                address {
                    line1
                    line2
                    city
                    county
                    state
                    zip
                }
                zone {
                    id
                    name
                    counties
                }
                staff {
                    type
                    title
                    name {
                        first
                        last
                    }
                }
                schools {
                    items {
                        id
                        slug
                        name {
                            legal
                            popular
                        }
                        avatar {
                            size
                            name
                            src
                        }
                        state {
                            enabled
                        }
                    }
                }
                users {
                    items {
                        id
                        username
                        avatar {
                            size
                            name
                            src
                        }
                    }
                }
                state {
                    enabled
                }
                createdAt
                updatedAt
                districtZoneId
            }
            nextToken
        }
    }
`;

export const updateDistrict = /* GraphQL */ `
    mutation UpdateDistrict(
        $input: UpdateDistrictInput!
        $condition: ModelDistrictConditionInput
    ) {
        updateDistrict(input: $input, condition: $condition) {
            id
        }
    }
`;

export const updateSchool = /* GraphQL */ `
    mutation UpdateSchool(
        $input: UpdateSchoolInput!
        $condition: ModelSchoolConditionInput
    ) {
        updateSchool(input: $input, condition: $condition) {
            id
        }
    }
`;

export const listZones = /* GraphQL */ `
    query ListZones(
        $id: ID
        $filter: ModelZoneFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listZones(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                counties
                state {
                    enabled
                }
            }
        }
    }
`;

export const onUpdateZone = /* GraphQL */ `
    subscription OnUpdateZone {
        onUpdateZone {
            id
            name
            state {
                enabled
            }
        }
    }
`;

export const listUserSchools = /* GraphQL */ `
    query ListUserSchools(
        $filter: ModelUserSchoolsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listUserSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                user {
                    id
                    username
                    name {
                        first
                        last
                    }
                    avatar {
                        size
                        name
                        src
                    }
                }
                school {
                    name {
                        legal
                    }
                }
            }
            nextToken
        }
    }
`;

export const deleteUserSchools = /* GraphQL */ `
    mutation DeleteUserSchools(
        $input: DeleteUserSchoolsInput!
        $condition: ModelUserSchoolsConditionInput
    ) {
        deleteUserSchools(input: $input, condition: $condition) {
            id
        }
    }
`;


