<template>
  <page-layout ref="layout">
    <template #breadcrumbs>
      <b-breadcrumb-item text="Management" />
      <b-breadcrumb-item text="Districts" :to="{ name: 'management-districts' }" />
      <b-breadcrumb-item :text="district.name.legal" active />
    </template>

    <template #actions="{ state }">
      <can do="update" on="management-district">
        <b-button v-if="state.editing" v-b-tooltip="'Update'" variant="transparent" size="sm" @click="updateDistrict">
          <font-awesome-icon icon="fa-solid fa-cloud-arrow-up" />
        </b-button>
      </can>
    </template>

    <template #dropdown-options="{ state }">
      <can do="update" on="management-district">
        <b-dropdown-item @click="state.editing = !state.editing">
          <feather-icon icon="EditIcon"/>
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>
        <b-dropdown-item @click="updateState">
          <font-awesome-icon :icon="['fas', district.state.enabled ? 'toggle-off' : 'toggle-on']"/>
          <span class="align-middle ml-50">{{ district.state.enabled ? 'Disable' : 'Enable' }}</span>
        </b-dropdown-item>
        <can do="delete" on="management-district">
          <b-dropdown-item @click="$refs.layout.confirmDelete(district, deleteDistrict, cascadeConfirmDeleteOptions)">
            <feather-icon icon="Trash2Icon"/>
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </can>
        <b-dropdown-divider/>
      </can>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content="{ state }">
      <validation-observer v-if="district" ref="observer" tag="div">
        <form ref="form">
          <b-row>
            <b-col cols="8">
              <b-card>
                <b-row>
                  <b-col align-self="start" cols="auto">
                    <b-avatar v-if="hasCustomAvatar(district.avatar)" v-b-modal.avatar-modal variant="primary" size="8em" badge-variant="white" :disabled="!state.editing">
                      <b-img fluid fluid-grow :src="district.avatar.src" :alt="district.avatar.name"></b-img>
                      <template #badge>
                        <b-icon :icon="enabledIcon(district.state.enabled)" :variant="enabledIconVariant(district.state.enabled)"></b-icon>
                      </template>
                    </b-avatar>
                    <b-avatar v-else v-b-modal.avatar-modal variant="primary" size="6em" badge-variant="white" :disabled="!state.editing">
                      <font-awesome-icon :icon="icon" size="3x"></font-awesome-icon>
                      <template #badge>
                        <b-icon :icon="enabledIcon(district.state.enabled)" :variant="enabledIconVariant(district.state.enabled)"></b-icon>
                      </template>
                    </b-avatar>
                    <avatar-modal v-if="state.editing"
                                  title="District Avatar"
                                  :avatar="district.avatar"
                                  @update-avatar="updateAvatar"/>
                  </b-col>
                  <b-col align-self="center">
                    <b-row>
                      <b-col cols="6">
                        <validation-provider v-slot="validationContext" vid="legal-name" name="Legal Name" rules="required">
                          <b-form-group label="Legal Name" label-for="legal-name-input" :invalid-feedback="validationContext.errors[0]">
                            <b-input v-model="district.name.legal" :disabled="!state.editing"></b-input>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="6">
                        <validation-provider v-slot="validationContext" vid="popular-name" name="Popular Name" rules="required">
                          <b-form-group label="Popular Name" label-for="popular-name-input" :invalid-feedback="validationContext.errors[0]">
                            <b-input v-model="district.name.popular" :disabled="!state.editing"></b-input>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col>
              <b-card>
                <validation-provider v-slot="validationContext" vid="zone" name="Zone" rules="required">
                  <b-form-group label="Zone" label-for="form-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                    <v-select id="zone-input"
                              v-model="district.zone"
                              :disabled="!state.editing"
                              label="name"
                              :options="options.zones.items"
                              :loading="options.zones.loading"
                              :selectable="option => option.state.enabled === true"
                              :select-on-tab="true"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              placeholder="Zone"
                              class="form-control-plaintext w-100"
                              @input="district.address.county = null">
                      <template #option="{ name, state }">
                        <div class="d-flex justify-content-between">
                          <span>{{ name }}</span> <span v-if="!state.enabled" title="Disabled"><b-icon-lock-fill /></span>
                        </div>
                      </template>
                    </v-select>
                  </b-form-group>
                </validation-provider>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card title="Address">
                <b-row>
                  <b-col>
                    <validation-provider v-slot="validationContext" vid="line1" name="Line 1" rules="required">
                      <b-form-group label="Line 1" label-for="line1-input" :invalid-feedback="validationContext.errors[0]">
                        <b-form-input id="line1-input" v-model="district.address.line1" :disabled="!state.editing" :state="getValidationState(validationContext)"></b-form-input>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col>
                    <validation-provider v-slot="validationContext" vid="line2" name="Line 2" rules="">
                      <b-form-group label="Line 2" label-for="line2-input" :invalid-feedback="validationContext.errors[0]">
                        <b-form-input id="line2-input" v-model="district.address.line2" :disabled="!state.editing" :state="getValidationState(validationContext)"></b-form-input>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <validation-provider v-slot="validationContext" vid="city" name="City" :rules="rules.city">
                      <b-form-group label="City" label-for="city-input" :invalid-feedback="validationContext.errors[0]">
                        <b-form-input id="city-input" v-model="district.address.city" :disabled="!state.editing" :state="getValidationState(validationContext)"></b-form-input>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col>
                    <validation-provider v-slot="validationContext" vid="county" name="County" rules="required">
                      <b-form-group label="County" label-for="county-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                        <v-select id="county-input"
                                  v-model="district.address.county"
                                  :options="counties"
                                  :select-on-tab="true"
                                  :disabled="!state.editing"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  class="w-100"/>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="auto">
                    <validation-provider v-slot="validationContext" vid="state" name="State" :rules="rules.state">
                      <b-form-group label="State" label-for="state-input" :invalid-feedback="validationContext.errors[0]">
                        <state-input id="state-input" v-model="district.address.state" :disabled="!state.editing" :validation-state="getValidationState(validationContext)"/>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="auto">
                    <validation-provider v-slot="validationContext" vid="zip-code" name="Zip Code" :rules="rules.zip">
                      <b-form-group label="Zip Code" label-for="zip-code-input" :invalid-feedback="validationContext.errors[0]">
                        <zip-input id="zip-code-input" v-model="district.address.zip" :disabled="!state.editing" :state="getValidationState(validationContext)"/>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <!-- Schools -->
            <b-col cols="12" lg="4">
              <b-card no-body>
                <b-card-body>
                  <b-row>
                    <b-col align-self="center">
                      <b-card-title class="mb-0">Schools</b-card-title>
                    </b-col>
                    <b-col align-self="center" cols="auto" class="pr-0">
                      <b-button v-if="state.editing" v-b-modal.school-modal v-b-tooltip="'Create'" variant="transparent" size="sm" >
                        <font-awesome-icon icon="fa-solid fa-plus"></font-awesome-icon>
                      </b-button>
                      <school-modal id="school-modal" :district="district" @created="district.schools.items.push($event)"/>
                    </b-col>
                  </b-row>
                </b-card-body>
                <b-list-group flush>
                  <template v-if="district && district.schools.items.length">
                    <b-list-group-item v-for="school in district.schools.items" :key="school.id" class="d-flex justify-content-between align-items-center">
                      <b-media vertical-align="center" no-body>
                        <b-media-aside>
                          <b-avatar v-if="hasCustomAvatar(school.avatar)" v-b-modal.avatar-modal variant="light-primary" size="3.25em" badge-variant="white" :disabled="!state.editing">
                            <b-img fluid fluid-grow :src="school.avatar.src" :alt="school.avatar.name"></b-img>
                            <template #badge>
                              <b-icon :icon="enabledIcon(school.state.enabled)" :variant="enabledIconVariant(school.state.enabled)"></b-icon>
                            </template>
                          </b-avatar>
                          <b-avatar v-else v-b-modal.avatar-modal variant="light-primary" size="3.25em" badge-variant="white" :disabled="!state.editing">
                            <font-awesome-icon icon="fa-solid fa-school" size="lg"></font-awesome-icon>
                            <template #badge>
                              <b-icon :icon="enabledIcon(school.state.enabled)" :variant="enabledIconVariant(school.state.enabled)"></b-icon>
                            </template>
                          </b-avatar>
                          <b-media-body class="ml-1 align-self-center">
                            <template v-if="school && school.name">
                              <h6 class="mb-0">{{ school.name.legal }}</h6>
                              <small>{{ school.name.popular }}</small>
                            </template>
                            <template v-else>
                              <h6 class="mb-0">No Name</h6>
                              <small>No Name</small>
                            </template>
                          </b-media-body>
                        </b-media-aside>
                      </b-media>

                      <b-dropdown variant="link" toggle-class="p-0" no-caret :right="$store.state.appConfig.isRTL">
                        <template #button-content>
                          <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
                        </template>
                        <b-dropdown-item :to="{ name: 'management-school', params: { id: school.id, slug: school.slug } }">
                          <feather-icon icon="EditIcon" />
                          <span class="align-middle ml-50">View</span>
                        </b-dropdown-item>
                        <can do="delete" on="management-school">
                          <b-dropdown-item v-if="state.editing" @click="$refs.layout.confirmDelete(school, deleteSchool, cascadeDeleteSchoolOptions)">
                            <feather-icon icon="Trash2Icon"/>
                            <span class="align-middle ml-50">Delete</span>
                          </b-dropdown-item>
                        </can>
                      </b-dropdown>
                    </b-list-group-item>
                  </template>
                  <template v-else>
                    <b-list-group-item>
                      <span class="text-muted">
                         No schools associated to this district
                      </span>
                    </b-list-group-item>
                  </template>
                </b-list-group>
              </b-card>
            </b-col>

            <!-- Staff -->
            <b-col cols="12" lg="4">
              <b-card no-body>
                <b-card-body>
                  <b-row>
                    <b-col align-self="center">
                      <b-card-title class="mb-0">Staff</b-card-title>
                    </b-col>
                    <b-col align-self="center" cols="auto">
                      <b-button v-if="state.editing" v-b-toggle.sidebar-staff-add size="sm" variant="link" class="pr-0">
                        <feather-icon icon="PlusIcon" size="16" class="align-middle text-body"/>
                      </b-button>

                      <staff-sidebar @add="addStaff"></staff-sidebar>
                    </b-col>
                  </b-row>
                </b-card-body>

                <b-list-group flush>
                  <template v-if="district.staff.length">
                    <b-list-group-item v-for="(staff, index) in district.staff" :key="index">
                      <staff-item :staff="staff" :editing="state.editing" @remove="removeStaff(index)"></staff-item>
                    </b-list-group-item>
                  </template>
                  <template v-else>
                    <b-list-group-item>
                      <span class="text-muted">
                         No staff associated to this school
                      </span>
                    </b-list-group-item>
                  </template>
                </b-list-group>
              </b-card>
            </b-col>

            <!-- Teachers -->
            <b-col cols="12" lg="4">
              <b-card no-body>
                <b-card-body>
                  <b-card-title class="mb-0">Teachers</b-card-title>
                </b-card-body>

                <b-overlay :show="users.loading" :opacity="1" variant="white">
                  <b-list-group flush>
                    <template v-if="users.items.length">
                      <b-list-group-item v-for="user in users.items" :key="user.id" class="d-flex justify-content-between align-items-center">
                        <b-media no-body>
                          <b-media-aside class="mr-75">
                            <b-avatar v-if="hasCustomAvatar(user.avatar)" variant="light-primary" size="3.25em">
                              <b-img fluid fluid-grow :src="user.avatar.src" :alt="user.avatar.name"></b-img>
                            </b-avatar>
                            <b-avatar v-else variant="light-primary" size="3.25em">
                              <font-awesome-icon icon="fa-solid fa-chalkboard-user" size="lg"/>
                            </b-avatar>
                          </b-media-aside>
                          <b-media-body class="my-auto">
                            <h6 class="mb-0">
                              {{ user.name.first }} {{ user.name.last }}
                            </h6>
                            <small v-for="(school, index) in user.schools" :key="school.id">
                              {{ school.name.legal }}{{ index !== (user.schools.length-1) ? ', ' : null }}
                            </small>
                          </b-media-body>
                        </b-media>
                        <can do="read" on="management-user">
                          <b-dropdown variant="link" toggle-class="p-0" no-caret :right="$store.state.appConfig.isRTL" dropright>
                            <template #button-content>
                              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
                            </template>
                            <b-dropdown-item :to="{ name: 'management-user', params: { id: user.id, username: user.username } }">
                              <feather-icon icon="EditIcon" />
                              <span class="align-middle ml-50">View</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </can>
                      </b-list-group-item>
                    </template>
                    <template v-else>
                      <b-list-group-item>
                      <span class="text-muted">
                         No users associated to this district
                      </span>
                      </b-list-group-item>
                    </template>
                  </b-list-group>
                </b-overlay>
              </b-card>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </template>

    <template #debug>
      <b-row>
        <b-col cols="4">
          <debug title="District">{{ district }}</debug>
        </b-col>
        <b-col cols="4">
          <debug title="Zones" collapsed>{{ options.zones }}</debug>
        </b-col>
        <b-col cols="4">
          <debug title="Counties" collapsed>{{ counties }}</debug>
        </b-col>
      </b-row>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import AvatarModal from '@/components/Avatar/AvatarModal.vue';
import SchoolModal from '@/views/management/schools/SchoolModal.vue';
import vSelect from 'vue-select'
import avatar from '@/mixins/avatar.mixin'
import role from '@/mixins/role.mixin'
import status from '@/mixins/status.mixin'
import print from '@/mixins/print.mixin'
import notify from '@/mixins/notify.mixin'
import slugify from 'slugify';
import StaffSidebar from '@/components/StaffSidebar.vue';
import StaffItem from '@/components/StaffItem.vue';
import {rules} from '@/data/validation-rules';
import StateInput from '@/components/StateInput.vue';
import ZipInput from '@/components/ZipInput.vue';
import {onUpdateZone} from '@/graphql/queries/zones';
import { API, graphqlOperation } from 'aws-amplify';
import {getDistrict, getDistrictBySlug, updateDistrict, updateSchool, listZones, listUserSchools } from './queries/district';
import {cascadeDeleteDistrict, cascadeConfirmDeleteOptions} from '@/graphql/cascade/district';
import {cascadeDeleteSchool, cascadeConfirmDeleteOptions as cascadeDeleteSchoolOptions} from '@/graphql/cascade/school';
import {updateApplication} from '@/views/dev/fix-app-user';

export default {
  components: {
    PageLayout,
    ZipInput,
    StateInput,
    StaffSidebar,
    StaffItem,
    SchoolModal,
    AvatarModal,
    vSelect
  },
  mixins: [ avatar, role, status, print, notify ],
  props: {
    id: {
      type: String,
      required: false,
      default: null
    },
    slug: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      district: null,
      users: {
        items: [],
        loading: true
      },
      options: {
        zones: {
          items: [],
          loading: true,
          subscription: null
        }
      },
      rules: {
        city: rules.address.city,
        state: rules.address.state,
        zip: rules.address.zip
      },
      icon: 'fa-solid fa-building-columns',
      cascadeConfirmDeleteOptions,
      cascadeDeleteSchoolOptions
    }
  },
  computed: {
    counties() {
      if(this.options.zones.loading) return []
      return this.district.zone ? this.options.zones.items.find(zone => zone.id === this.district.zone.id)?.counties?.sort((a, b) => a.localeCompare(b)) : []
    },
  },
  async mounted() {
    await this.getDistrict();
    await this.getZones();
    await this.listUsers()
    await this.onUpdateZone()
  },
  beforeDestroy() {
    this.options.zones.subscription.unsubscribe()
  },
  methods: {
    /** District **/
    async getDistrict() {
      if(this.id) {
        const response = await API.graphql(graphqlOperation(getDistrict, { id: this.id }));
        this.district = response.data.getDistrict
      }
      else {
        const response = await API.graphql(graphqlOperation(getDistrictBySlug, { slug: this.slug }));
        // eslint-disable-next-line prefer-destructuring
        this.district = response.data.getDistrictBySlug.items[0]
      }
      this.$refs.layout.state.loading = false
    },
    async patchDistrict(input) {
      try {
        await API.graphql(graphqlOperation(updateDistrict, { input: input } ));
        this.notify({ title: 'Success', text: 'District was successfully updated', icon: 'fas fa-code-branch', variant: 'success' });
      }
      catch(error) {
        this.notify({ title: 'Error', text: 'District failed to update', icon: 'fas fa-code-branch', variant: 'danger'});
      }
    },
    async updateDistrict() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        await this.patchDistrict({
          id: this.district.id,
          name: this.district.name,
          slug: slugify(this.district.name.legal, { lower: true }),
          address: this.district.address,
          districtZoneId: this.district.zone ? this.district.zone.id : null
        })
        await this.district.schools.items.reduce(async (referencePoint, school) => {
          try {
            await referencePoint;
            await API.graphql(graphqlOperation(updateSchool, { input: { id: school.id, schoolZoneId: this.district.zone ? this.district.zone.id : null} } ));
          }
          catch (e) {
            console.error(e)
          }
        }, Promise.resolve());
      }
      else {
        this.notify({ title: 'Warning', text: 'District failed to update. Missing required fields.', icon: 'fas fa-server', variant: 'warning'});
      }
    },
    async deleteDistrict(district, swalCallback) {
      try {
        await this.cascadeDeleteDistrict(district.id, swalCallback)
        await this.notify({ title: 'Success', text: 'District was successfully deleted', icon: this.icon, variant: 'success' });
        await this.$router.push({ name: 'management-districts' })
      }
      catch (error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'District failed to delete', icon: this.icon, variant: 'danger' });
        throw error //for swal
      }
    },
    cascadeDeleteDistrict,

    // eslint-disable-next-line no-shadow
    async updateAvatar(avatar) {
      this.district.avatar = avatar;
      await this.patchDistrict({ id: this.district.id, avatar: this.district.avatar })
    },
    async updateState() {
      this.district.state.enabled = !this.district.state.enabled;
      await this.patchDistrict({ id: this.district.id, state: this.district.state })
    },

    /** School **/
    async deleteSchool(school, swalCallback) {
      await this.cascadeDeleteSchool(school.id, swalCallback)
      await this.notify({ title: 'Success', text: 'School was successfully deleted', icon: this.icon, variant: 'success' });
      this.district.schools.items = this.district.schools.items.filter(item => item.id !== school.id)
    },
    cascadeDeleteSchool,

    /** Zone **/
    async getZones() {
      const response = await API.graphql(graphqlOperation(listZones));
      this.options.zones.items = response.data.listZones.items?.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }));
      this.options.zones.loading = false
    },
    async onUpdateZone() {
      this.options.zones.subscription = API.graphql(graphqlOperation(onUpdateZone)).subscribe((sourceData) => {
        this.syncNotification()
        const zone = sourceData.value.data.onUpdateZone
        if (zone && this.options.zones.items.map(item => item.id).includes(zone.id)) {
          this.options.zones.loading = true
          const index = this.options.zones.items.findIndex(item => item.id === zone.id)
          if(index > -1) {
            this.options.zones.items.splice(index, 1, zone)
          }
          this.options.zones.loading = false
        }
      });
    },

    /** User **/
    async listUsers() {
      this.users.loading = true

      if( this.district.schools.items.length) {
        const filter = { or: [ ] }
        this.district.schools.items.forEach(school => {
          filter.or.push({ schoolID: { eq: school.id } })
        })

        const response = await API.graphql(graphqlOperation(listUserSchools, { filter: filter }));
        const users = response.data.listUserSchools.items.map(item => ({ ...item.user, school: item.school }));
        this.users.items = [...new Map(users.map(item => [item.id, item])).values()];
        this.users.items.forEach(item => {
          item.schools = users.filter(user => user.id === item.id).map(user => user.school)
        })
      }
      this.users.loading = false
    },

    /** Util **/
    async refresh() {
      this.$refs.layout.state.loading = true
      await this.getDistrict()
      this.$refs.layout.state.loading = false
    },
    getValidationState({ dirty, validated, valid = null }) {
      if(this.$refs.layout.state.editing) {
        return dirty || validated ? valid : null;
      }
      return null;
    },
    addStaff(staff) {
      this.district.staff.push(staff)
    },
    removeStaff(index) {
      this.district.staff.splice(index, 1)
    },
  },
}
</script>

<style lang="scss">
  @import '~@core/scss/vue/libs/vue-select.scss';

  .per-page-selector {
    min-width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
</style>
